import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
    fill: '#ffffff',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      viewBox="0 0 415.4 97.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classes.svg}
    >
      <g id="Layer_2">
        <g id="Logo">
          <path d="M141.3,75.3V53h-19.6v22.3h-10v-53h10v21.8h19.6V22.4h10v53H141.3z" />
          <path
            d="M187.9,75.3H180l-0.7-2.6c-3.4,2.2-7.4,3.4-11.5,3.4c-7.1,0-10.1-4.8-10.1-11.5c0-7.9,3.4-10.9,11.3-10.9h9.3
                    v-4.1c0-4.3-1.2-5.8-7.4-5.8c-3.6,0-7.2,0.4-10.7,1.1l-1.2-7.4c4.3-1.2,8.8-1.8,13.3-1.8c12.2,0,15.7,4.3,15.7,14L187.9,75.3z
                    M178.2,60.7h-7.1c-3.2,0-4.1,0.9-4.1,3.8c0,2.7,0.9,3.9,3.9,3.9c2.6,0,5.1-0.7,7.3-2L178.2,60.7z"
          />
          <path
            d="M206.7,76.1c-4.5-0.1-9-0.8-13.4-2.2l1.4-7.4c3.8,1.1,7.6,1.6,11.5,1.7c4.3,0,4.9-1,4.9-3.9
                    c0-2.4-0.5-3.6-6.8-5.1c-9.5-2.3-10.6-4.7-10.6-12.2c0-7.8,3.4-11.2,14.5-11.2c3.9,0,7.8,0.5,11.6,1.3l-1,7.7
                    c-3.5-0.6-7.1-1-10.6-1.1c-4.2,0-4.9,1-4.9,3.3c0,3.1,0.2,3.3,5.5,4.7c10.8,2.9,11.9,4.3,11.9,12.2
                    C220.7,71.5,218.4,76.1,206.7,76.1z"
          />
          <path
            d="M249.6,75.3v-27c0-2.1-0.9-3.1-3.1-3.1c-2.4,0-6.6,1.4-10.1,3.3v26.8h-9.7V20.8l9.7-1.4v20.1
                    c4.4-2.3,9.2-3.5,14.1-3.7c6.4,0,8.7,4.5,8.7,11.5v28.1H249.6z"
          />
          <path d="M266.4,31.1V19.9h9.7v11.2H266.4z M266.4,75.3V36.6h9.7v38.7H266.4z" />
          <path
            d="M282.9,36.9c0-9.7,5.6-15.3,18.8-15.3c4.8,0,9.7,0.6,14.4,1.8L315,32c-4.3-0.8-8.6-1.3-13-1.4
                    c-6.9,0-9.1,2.4-9.1,8V59c0,5.6,2.2,8,9.1,8c4.4-0.1,8.7-0.5,13-1.3l1.1,8.7c-4.7,1.2-9.5,1.8-14.4,1.8
                    c-13.2,0-18.8-5.6-18.8-15.3L282.9,36.9z"
          />
          <path
            d="M336,76.1c-13.3,0-16.9-7.3-16.9-15.3v-9.8c0-8,3.6-15.3,16.9-15.3s16.9,7.3,16.9,15.3v9.8
                    C352.8,68.8,349.2,76.1,336,76.1z M336,44.1c-5.2,0-7.1,2.3-7.1,6.7v10.4c0,4.4,2,6.7,7.1,6.7s7.1-2.3,7.1-6.7V50.8
                    C343.1,46.4,341.1,44.1,336,44.1L336,44.1z"
          />
          <path d="M378.6,44.7c-3.6,1.6-7,3.5-10.3,5.6v25h-9.7V36.6h8.2l0.6,4.3c3.2-2.1,6.6-3.8,10.2-5.1L378.6,44.7z" />
          <path
            d="M415.4,62.5c0,8.6-3.8,13.7-12.8,13.7c-3.5,0-6.9-0.4-10.3-1.1v16l-9.7,1.3V36.6h7.7l1,3.3
                    c3.5-2.6,7.8-4.1,12.2-4c7.8,0,11.9,4.6,11.9,13.4L415.4,62.5z M392.2,66.8c2.8,0.6,5.7,1,8.6,1c3.5,0,4.9-1.7,4.9-5.2V49
                    c0-3.2-1.2-4.9-4.8-4.9c-3.2,0.1-6.3,1.4-8.7,3.5V66.8z"
          />
          <polygon points="38.2,0 0,22 0,22.1 0,75 14.4,83.3 14.4,30.3 38.2,16.6              " />
          <polygon
            points="53.5,0 53.5,42.2 38.2,42.2 38.2,26.4 23.9,34.7 23.9,88.8 38.2,97.1 38.2,55 53.5,55 53.5,70.6
                    67.8,62.4 67.8,8.3          "
          />
          <polygon points="53.5,97.1 91.7,75 91.7,75 91.7,22.1 77.3,13.8 77.3,66.7 53.5,80.5          " />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
