import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    fill: '#ffffff',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      viewBox="0 0 106.9 113.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classes.svg}
    >
      <g id="Layer_2">
        <g id="Logo">
          <polygon points="44.5,0 0,25.7 0,25.7 0,87.4 16.7,97.1 16.7,35.3 44.5,19.3              " />
          <polygon
            points="62.3,0 62.3,49.2 44.5,49.2 44.5,30.8 27.8,40.5 27.8,103.4 44.5,113.1 44.5,64.1 62.3,64.1
                62.3,82.3 79.1,72.7 79.1,9.7            "
          />
          <polygon points="62.3,113.1 106.9,87.4 106.9,87.4 106.9,25.7 90.1,16.1 90.1,77.8 62.3,93.8              " />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
