import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';

export const hashicorpDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      navigation: {
        background: '#000000',
        indicator: '#ffffff',
        color: '#b5b5b5',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          backgroundImage: 'unset',
          backgroundColor: '#000000',
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          display: 'none',
        },
      },
    },
  },
});
